

















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class Search extends Vue {
  @Prop() private isGJ!: false;
  @Prop() private isCZ!: false;
  gaoji = false;

  Search() {
    this.$emit("Search");
  }

  searchJson = {};

  created() {
    this.searchJson = { ...(this.$parent as any).search };
  }

  reset() {
    (this.$parent as any).search = { ...this.searchJson };
    this.Search();
  }
}
